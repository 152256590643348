@import "./Variables/colors.scss";

.work-section {
  text-align: center;
  background-color: $background;
  padding: 110px 0;
  h2{
    margin-bottom: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 2.5rem;
  }
  h4{
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    margin: 0 0 15px 0;
  }
  .container-work{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 10px;
   padding: 0 7%;
   .box-work{
      position: relative;
      width: 100%;
      max-width: 400px;
    height: 400px;
      overflow: hidden;
      .desc-work{
        background: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 20px;
        color: #fff;
        z-index: 1;
        p{
          display: none;
          
        }
        &:hover{
          p{
          display: block;
          }
          
        }
        @media (max-width: 792px) {
          p{
            display: block;
          }
        }
      }
      img{
        width: 100%;
        max-width: 400px;
        transition: transform 0.4s ease-in-out;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
      &:hover{
      img{
        transform: scale(1.1) translate(-50%, -50%);
        }
      }
    }
  }
}