@import "./Variables/colors.scss";

.technologies {
  color: $main-font-color;
  background: $background-gradient;
  text-align: center;
  padding: 72px 0;
  p{
    font-size: 1.5rem;
    line-height: 26px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-align: left;
    @media (max-width: 792px) {
      font-size: 1rem;
      line-height: 16px;
    }
  }
  
  h2 {
    margin: 0;
    margin-bottom: 50px;
    font-size: 2.5rem;
    font-weight: 700;
    @media (max-width: 792px) {
      font-size: 24px;
    }
  }
  .container{
    display: flex;
    justify-content: center;
  ul{
    text-align: left;
    li{
      font-weight: 300;
      font-size: 1.25rem;
      @media (max-width: 792px) {
        font-size: 1rem;
        line-height: 16px;
      }
    }
  }
}
  .technologies-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0 7% 110px 7%;
    @media (max-width: 792px) {
      padding: 0 5% 110px 5%;
    }
    .technology {
      font-size: 0.8rem;
      margin: 60px 17px;
      padding: 30px 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border: 3px solid #fff;
      border-radius: 8px;
      width: 500px;
      text-align: center;
      @media (max-width: 792px) {
        margin: 60px 0;
        padding: 30px ;
      }
      img {
        margin-bottom: 40px;
        @media (max-width: 792px) {
          max-width: 250px;
        }
      }
      h4 {
        margin-bottom: 40px;
        font-size: 32px;
        font-weight: 700;
        @media (max-width: 792px) {
          font-size: 24px;
        }
      }
      p {
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 22px;
        @media (max-width: 792px) {
          font-size: 0.8rem;
        }
      }
    }
  }
}
