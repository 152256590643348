@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  z-index: -1;
  color: #fff;
  height: 100%;
  //   background-color: #000000;
}
* {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
}
section {
  position: relative;
  font-family: "Rubik", sans-serif;
}
