@import "./Variables/colors.scss";

.about {
  background-color: $background;
  color: $main-font-color;
  text-align: center;
  padding: 72px 18% 110px 18%;
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 50px;
    @media (max-width: 792px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 26px;
    padding: 0;
    margin: 0;
    @media (max-width: 792px) {
      font-size: 1rem;
      line-height: 16px;
    }
  }
  @media (max-width: 792px) {
    padding: 72px 5% 110px 5%;
  }
}
