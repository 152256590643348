@import "./Variables/colors.scss";

// section{
//   background: $background;
.contact-section {
  text-align: center;
  
  padding: 72px 0;
  color: #fff;
  @media (max-width: 792px) {
    padding: 72px 5%;
  }
  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 50px;
    @media (max-width: 792px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 22px;
    margin: 0;
    margin-bottom: 50px;
    @media (max-width: 792px) {
      font-size: 1rem;
      line-height: 16px;
    }
  }
  a {
    color: #fff;
    background: $button-color;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 22px;
    padding: 10px 100px 14px 100px;
    text-decoration: none;
    border-radius: 8px;
    @media (max-width: 792px) {
      font-size: 0.8rem;
    }
  }
  .movies{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
   flex-wrap: wrap;
    article{
      padding-right: 40px;
      @media (max-width: 792px) {
      padding: 0;
      }
      video{
        border-radius: 8px;
        max-width: 500px;
        @media (max-width: 792px) {
          max-width: 300px;
        }
      }
    }
  }
  background: $background;
}
// }