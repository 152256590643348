@import "./Variables/colors.scss";

footer {
  position: relative;
  color: $main-font-color;
  padding-top: 200px;
  background: $background-gradient;
  .footer-content {
    background: #000000;
    padding: 32px 90px 42px 90px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 792px) {
      padding: 32px 5px 42px 40px;
    }
    a{
      color: $main-font-color;
      font-size: 0.9rem;
      font-weight: 300;
      text-decoration: none;
      transition: 0.4s;
      &:hover{
        color: $background; 
        padding: 4px;
      }
    }
    .license{
      font-size: 0.75rem;
      max-width: 500px;
      color: #6f6d6d;
    }
  }
}
