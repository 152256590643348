@import "./Variables/colors.scss";

.main-header {
  background-color: transparent;
  height: 100vh;
  color: $main-font-color;
  position: relative;
  @media (max-width: 792px) {
    padding-right: 8%;
  }
  .header-logo {
    margin: 15% 0 0 10%;
    width: auto;
    height: 100%;
    max-height: 180px;
    @media (max-width: 792px) {
      width: 100%;
      height: auto;
      max-width: 280px;
      margin: 15% 5% 0 5%;
    }
  }
  .header-text {
    width: 100%;
    max-width: 600px;
    padding: 10px 10px 0 50px;
    margin-left: 10%;
    margin-top: 50px;
    font-weight: 300;
    font-size: 1.2rem;
    border-top: 1px solid $line;
    @media (max-width: 792px) {
      margin-left: 5%;
      margin-right: 5%;
      padding: 10px;
    }
  }
  .scroll-to-explore {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 792px) {
bottom: 100px;
    }
  }
}
